var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("卡片3")]), _c('Row', {
    staticClass: "n-m-r-0",
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "title": "应用中心",
      "description": "销量统计，用户统计，日活分析",
      "image": require('@/assets/icon/app.png'),
      "width": "34px",
      "height": "34px"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "backgroundImage": 'url(' + require('@/assets/dashboard/balloon.svg') + ')',
      "icon": "md-cart",
      "title": "购物车",
      "description": "销量统计，用户统计，日活分析"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "bordered": false,
      "backgroundColor": "#8851fc",
      "icon": "logo-bitcoin",
      "iconColor": "#fff",
      "title": "数字货币",
      "titleColor": "#fff",
      "description": "销量统计，用户统计，日活分析",
      "descriptionColor": "#fff"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };