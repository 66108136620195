var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Card', {
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "padding": 0,
      "bordered": _vm.bordered
    }
  }, [_c('div', {
    staticClass: "card-content card3",
    style: {
      backgroundImage: _vm.backgroundImage
    }
  }, [_c('div', {
    staticClass: "card-body",
    style: {
      height: _vm.cardHeight
    }
  }, [_vm.icon ? _c('Icon', {
    attrs: {
      "type": _vm.icon,
      "color": _vm.iconColor,
      "size": _vm.iconSize
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.image,
      "width": _vm.width,
      "height": _vm.height
    }
  }), _c('div', [_c('div', {
    staticClass: "card-title",
    style: {
      color: _vm.titleColor,
      fontSize: _vm.titleSize,
      fontWeight: _vm.titleWeight,
      marginBottom: _vm.titleBottom
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "card-description",
    style: {
      color: _vm.descriptionColor,
      fontSize: _vm.descriptionSize,
      fontWeight: _vm.descriptionWeight,
      marginBottom: _vm.descriptionBottom
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };